{
  ".data.once": [159204, 568],
  ".data..percpu": [162624, 5100],
  ".data..percpu..shared_aligned": [194176, 3216],
  ".init.setup": [647812, 888],
  "__param": [652056, 1800],
  ".initcallrootfs.init": [653904, 4],
  ".initcall1.init": [653908, 20],
  ".initcall6.init": [653928, 120],
  ".initcallearly.init": [654048, 32],
  ".initcall5.init": [654080, 32],
  ".initcall4.init": [654112, 60],
  ".initcall7.init": [662924, 16],
  ".initcall2.init": [663136, 28],
  ".con_initcall.init": [668432, 8],
  ".initcall3s.init": [668440, 4],
  ".initcall7s.init": [668444, 4]
}
